import { observer } from 'mobx-react-lite'
import React from 'react'
import StepStore, { QuestionId } from '../../../../utils/stepStore'
import { StaticImage } from 'gatsby-plugin-image'

import './results.styles.scss'
import {
  determineAutomationViability,
  UserSelections,
} from '../../../../utils/viabilityCheck'

import {
  FiDollarSign,
  FiThumbsUp,
  FiThumbsDown,
  FiActivity,
  FiSlash,
} from 'react-icons/fi'

import clsx from 'clsx'

const ResultStep: React.FC = observer(() => {
  const { getAnswer, resetFormulary } = StepStore

  const handleReset = () => {
    resetFormulary()
  }

  const params: UserSelections = {
    systems: getAnswer(QuestionId.SystemsUsed).map((i) => i),
    timeSpent: getAnswer(QuestionId.TimeSpent).map((i) => i)[0],
    strategicGain: getAnswer(QuestionId.StrategicGain).map((i) => i)[0],
  }
  const result = determineAutomationViability(params)

  console.log(result)

  const hasNotViability =
    result.viability === 'Não, aparentemente a automação não é viável.'

  const resultItem = clsx('result-item', {
    error: hasNotViability,
  })

  return (
    <div className="result-step">
      <div className="result-step-container">
        <div className="title">
          <h1>
            Com base nas suas respostas, aqui estão os resultados da viabilidade
            do seu projeto:
            <br />
            Descubra se a automação é viável, qual será o payback estimado e o
            retorno esperado para o seu investimento.
          </h1>
        </div>
        <div className="results">
          <div className={resultItem}>
            <div className="result-title">
              <span>A automação é viável?</span>
            </div>
            <div className="result-content">
              <div>
                {hasNotViability ? <FiSlash /> : <FiThumbsUp />}
                <span>{result.viability}</span>
              </div>
            </div>
          </div>
          <div className={resultItem}>
            <div className="result-title">
              <span>Qual é o payback provável?</span>
            </div>
            <div className="result-content">
              <div>
                {!hasNotViability && <FiDollarSign />}
                <span>{result.payback}</span>
              </div>
            </div>
          </div>
          <div className={resultItem}>
            <div className="result-title">
              <span>Qual é o retorno esperado?</span>
            </div>
            <div className="result-content">
              <div>
                {!hasNotViability && <FiActivity />}
                <span>{result.roi}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="disclaimer">
          <span>
            Projetos de implementação RPA a partir de R$9.900 mensais.
          </span>
        </div>
        <div className="actions">
          <button type="button" className="goBack" onClick={handleReset}>
            Fazer nova simulação
          </button>
          <StaticImage
            src="../../../../images/assets/lines-white.svg"
            alt="High jump lines"
            title="High jump lines"
            layout="constrained"
            placeholder="blurred"
          />
          <a
            href="https://api.whatsapp.com/send?phone=5516993982007"
            target="_blank"
          >
            Falar com um consultor
          </a>
        </div>
      </div>
    </div>
  )
})

export default ResultStep
