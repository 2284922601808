import React, { useMemo, useRef } from 'react'

import Lottie, { Options } from 'react-lottie'

import TypingTextComponent from '../../typing-text/typing-text'
import { StepNavigationProps } from '../../../../HOC/withStepNavigation'

import admData from '../../../../lotties/administrator.json'
import fiscalData from '../../../../lotties/fiscal.json'
import mktData from '../../../../lotties/marketing.json'
import moneyData from '../../../../lotties/money.json'
import planningData from '../../../../lotties/planning.json'
import salesData from '../../../../lotties/sales.json'
import supplyData from '../../../../lotties/supply.json'
import rhData from '../../../../lotties/rh.json'

import './business.styles.scss'
import { observer } from 'mobx-react-lite'
import StepStore from '../../../../utils/stepStore'
import { useMediaQuery } from '../../../../utils/hooks'

export enum CATEGORY_OPTION {
  FINANCEIRO = 1,
  ADM = 2,
  FISCAL = 3,
  COMERCIAL = 4,
  MKT = 5,
  RH = 6,
  SUPRIMENTOS = 7,
  PRODUCAO = 8,
}

export const lottieAnimationsData = [
  {
    option: CATEGORY_OPTION.FINANCEIRO,
    icon: fiscalData,
    label: 'Financeiro',
  },
  {
    option: CATEGORY_OPTION.ADM,
    icon: admData,
    label: 'Administrativo',
  },
  {
    option: CATEGORY_OPTION.FISCAL,
    icon: moneyData,
    label: 'Fiscal',
  },
  {
    option: CATEGORY_OPTION.COMERCIAL,
    icon: salesData,
    label: 'Comercial',
  },
  {
    option: CATEGORY_OPTION.MKT,
    icon: mktData,
    label: 'Marketing',
  },
  {
    option: CATEGORY_OPTION.RH,
    icon: rhData,
    label: 'RH',
  },
  {
    option: CATEGORY_OPTION.SUPRIMENTOS,
    icon: supplyData,
    label: 'Suprimentos',
  },
  {
    option: CATEGORY_OPTION.PRODUCAO,
    icon: planningData,
    label: 'Produçāo',
  },
]

const BusinessSelectorStep: React.FC<StepNavigationProps> = observer(
  ({ handleNextStep }) => {
    const lottieAnimations = lottieAnimationsData.map(
      (animationData, index) => {
        const lottieRef = useRef<any>(null)
        const { setSelectedCategory } = StepStore
        const isMobile = useMediaQuery(`768`)

        const defaultOptions: Options = {
          loop: false,
          autoplay: false,
          animationData: animationData.icon,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
          },
        }

        const handleMouseEnter = () => {
          if (lottieRef.current) {
            lottieRef.current.play()
          }
        }

        const handleMouseLeave = () => {
          if (lottieRef.current) {
            lottieRef.current.stop()
          }
        }

        const handleClick = (item: CATEGORY_OPTION) => {
          setSelectedCategory?.(item)
          handleNextStep?.()
        }

        return (
          <div
            className="lottie-item"
            key={index}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={() => handleClick(animationData.option)}
          >
            <Lottie
              ref={lottieRef}
              options={defaultOptions}
              width={isMobile ? 60 : 90}
              height={isMobile ? 50 : 80}
            />
            <span>{animationData.label}</span>
          </div>
        )
      }
    )

    const typingTexts = useMemo(() => {
      return [
        'Olá, vamos começar?',
        'Deseja automatizar o processo em qual área de negócio?',
      ]
    }, [])

    return (
      <div className="business-selector viability-default-content">
        <div className="typing-list">
          {typingTexts.map((text, index) => (
            <TypingTextComponent
              key={index}
              text={text}
              startDelay={index === 1 ? 2000 : 0}
            />
          ))}
        </div>

        <div className="category-content">
          <span>
            Selecione o departamento correspondente ao processo que será
            analisado.
          </span>

          <div className="categories">{lottieAnimations}</div>

          <div className="right-assets"></div>
        </div>
      </div>
    )
  }
)

export default BusinessSelectorStep
