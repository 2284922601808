import React, { useRef } from 'react'

import './summary.styles.scss'
import { observer } from 'mobx-react-lite'
import StepStore, { QuestionId } from '../../../utils/stepStore'
import { lottieAnimationsData } from '../steps/business'
import Lottie from 'react-lottie'
import {
  createQuestionMap,
  getLabelByValue,
  questionsById,
} from '../../../utils/questionsMap'
import { FaCheck } from 'react-icons/fa'

const stepMap: { [key: number]: number } = {
  3: 1,
  4: 2,
  5: 3,
}

const SummaryAside: React.FC = observer(() => {
  const { step, getSelectedCategory, setStep, getAnswer } = StepStore

  const handleNavigateToStep = (step: number) => {
    setStep(step)
  }

  const getQuestionById = (id: QuestionId) =>
    questionsById.find((f) => f.id === id)

  const getCategoryItem = () => {
    const lottieRef = useRef<any>(null)
    const category = lottieAnimationsData.find(
      (f) => f.option === getSelectedCategory()
    )

    const defaultOptions = {
      loop: false,
      autoplay: false,
      animationData: category?.icon,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    }

    const handleMouseEnter = () => {
      if (lottieRef.current) {
        lottieRef.current.play()
      }
    }

    const handleMouseLeave = () => {
      if (lottieRef.current) {
        lottieRef.current.stop()
      }
    }

    return (
      <div
        className="icon-item"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Lottie
          ref={lottieRef}
          options={defaultOptions}
          width={46}
          height={40}
          style={{ margin: '0' }}
        />
        <span>{category?.label}</span>
      </div>
    )
  }

  const getSelectedAnswers = (id: QuestionId) => {
    const findQuestion = getQuestionById(id)

    if (!findQuestion) return null

    const questionMap = createQuestionMap(findQuestion.questions as any)

    const currentAnswers = getAnswer(id)

    return currentAnswers.map((i) => {
      return (
        <div className="item">
          <FaCheck />
          <span>{getLabelByValue(i, questionMap)}</span>
        </div>
      )
    })
  }

  const counter = stepMap[step] || 1

  return (
    <div className="summary-aside">
      <div className="title">
        <span>Sumário</span>
        <small>{counter}/3</small>
      </div>

      <div className="content">
        {/* Category */}
        <div className="info-card">
          <div className="info-title">
            <span>Departamento</span>
            <button type="button" onClick={() => handleNavigateToStep(2)}>
              Alterar
            </button>
          </div>
          <div className="info-content">{getCategoryItem()}</div>
        </div>

        {[4, 5].includes(step) && (
          <div className="info-card">
            <div className="info-title">
              <span>{getQuestionById(QuestionId.SystemsUsed)?.label}</span>
            </div>
            <div className="info-content">
              <div className="info-options">
                {getSelectedAnswers(QuestionId.SystemsUsed)}
              </div>
              <div className="change-option">
                <button type="button" onClick={() => handleNavigateToStep(3)}>
                  Alterar
                </button>
              </div>
            </div>
          </div>
        )}

        {[5].includes(step) && (
          <div className="info-card">
            <div className="info-title">
              <span>{getQuestionById(QuestionId.TimeSpent)?.label}</span>
            </div>
            <div className="info-content">
              <div className="info-options">
                {getSelectedAnswers(QuestionId.TimeSpent)}
              </div>
              <div className="change-option">
                <button type="button" onClick={() => handleNavigateToStep(4)}>
                  Alterar
                </button>
              </div>
            </div>
          </div>
        )}

        {[6].includes(step) && (
          <div className="info-card">
            <div className="info-title">
              <span>{getQuestionById(QuestionId.StrategicGain)?.label}</span>
            </div>
            <div className="info-content">
              <div className="info-options">
                {getSelectedAnswers(QuestionId.StrategicGain)}
              </div>
              <div className="change-option">
                <button type="button" onClick={() => handleNavigateToStep(5)}>
                  Alterar
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
})

export default SummaryAside
