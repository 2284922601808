import { QuestionId } from './stepStore'

type QuestionOption = {
  label: string
  value: string
}

type QuestionMap = {
  [value: string]: string // Mapeia o value para o label
}

export const questionsOne: QuestionOption[] = [
  {
    label: 'ERP de mercado (SAP, TOTVS, Sankhya, Dynamics, etc)',
    value: 'erp-mercado',
  },
  {
    label: 'Sistema legado (próprio)',
    value: 'sistema-legado',
  },
  {
    label: 'E-mail',
    value: 'email',
  },
  {
    label: 'Sites diversos (governamentais ou não)',
    value: 'websites',
  },
  {
    label: 'Planilhas',
    value: 'planilhas',
  },
  {
    label: 'Internet banking',
    value: 'internet-banking',
  },
  {
    label: 'Manuscrito/papel',
    value: 'manuscrito',
  },
]

export const questionsTwo: QuestionOption[] = [
  {
    label: 'Até 32h mensais',
    value: '32',
  },
  {
    label: 'Até 96h mensais',
    value: '96',
  },
  {
    label: 'Até 160h mensais',
    value: '160',
  },
  {
    label: 'Acima de 160h mensais',
    value: '161',
  },
]

export const questionsThree: QuestionOption[] = [
  {
    label: 'Maior velocidade de atendimento',
    value: 'velocidade',
  },
  {
    label: 'Redução de erros',
    value: 'erros',
  },
  {
    label: 'Redução de custos com headcount',
    value: 'custos',
  },
  {
    label: 'Sigilo no processamento de informações',
    value: 'sigilo',
  },
  {
    label: 'Ganho não identificado',
    value: 'N/I',
  },
]

export const questionsById = [
  {
    id: QuestionId.SystemsUsed,
    label: 'Quais são os sistemas utilizados no processo?',
    questions: questionsOne,
  },
  {
    id: QuestionId.TimeSpent,
    label: 'Quanto tempo é gasto para execução deste processo mensalmente?',
    questions: questionsTwo,
  },
  {
    id: QuestionId.StrategicGain,
    label:
      'Certo, e além disso, há algum ganho estratégico esperado com a automação?',
    questions: questionsThree,
  },
]

// Função para criar o mapeamento
export const createQuestionMap = (questions: QuestionOption[]): QuestionMap => {
  return questions.reduce((map, question) => {
    map[question.value] = question.label
    return map
  }, {} as QuestionMap)
}

// Exemplo de busca do label pelo value
export const getLabelByValue = (
  value: string,
  questionMap: QuestionMap
): string | undefined => {
  return questionMap[value]
}
