import React, { useEffect, useState } from 'react'

import './typing-text.styles.scss'
import { ReactTyped } from 'react-typed'
import { StaticImage } from 'gatsby-plugin-image'

interface TypingTextComponentProps {
  text: string
  startDelay?: number
}

const TypingTextComponent: React.FC<TypingTextComponentProps> = React.memo(
  ({ text, startDelay }) => {
    const [shouldShowIcon, setShouldShowIcon] = useState(false)

    useEffect(() => {
      const timeoutId = setTimeout(() => {
        setShouldShowIcon(true)
      }, startDelay)

      return () => clearTimeout(timeoutId)
    }, [startDelay])

    return (
      <div className="typing-text">
        {shouldShowIcon && (
          <div className="typing-text__icon">
            <StaticImage
              src="../../../images/assets/lines-white.svg"
              alt="High jump lines"
              title="High jump lines"
              layout="constrained"
              placeholder="blurred"
            />
          </div>
        )}
        <ReactTyped
          strings={[text]}
          typeSpeed={30}
          startDelay={startDelay ? startDelay : 0}
          showCursor={false}
          className="typing-text__type"
        />
      </div>
    )
  }
)

export default TypingTextComponent
