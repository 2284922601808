import React, { PropsWithChildren, useCallback } from 'react'
import clsx from 'clsx'

import './base-layout.styles.scss'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

import logo from '../../../images/logo.svg'
import { observer } from 'mobx-react-lite'
import StepStore from '../../../utils/stepStore'
import { FiChevronLeft } from 'react-icons/fi'
import { useMediaQuery } from '../../../utils/hooks'

interface BaseLayoutProps extends PropsWithChildren {
  className: string
  asideContent?: React.ReactNode
  footerContent?: React.ReactNode
  hasNavigation?: boolean
  hasTitleIcon?: boolean
  hasSubmit?: boolean
  asideClass?: string
}

const BaseLayout: React.FC<BaseLayoutProps> = observer(
  ({
    asideContent,
    className,
    footerContent,
    hasNavigation,
    hasTitleIcon,
    hasSubmit,
    asideClass,
    children,
  }) => {
    const {
      nextStep,
      previousStep,
      completeFormulary,
      step,
      isAnswerSelected,
    } = StepStore

    const isMobile = useMediaQuery(`768`)

    const titleClass = clsx('header', {
      hasIcon: hasTitleIcon,
    })

    const handleNextStep = useCallback(() => {
      nextStep()
    }, [StepStore])

    const handlePreviousStep = useCallback(() => {
      previousStep()
    }, [StepStore])

    const handleSubmit = useCallback(() => {
      completeFormulary()
    }, [StepStore])

    return (
      <section
        className={clsx('base-layout', className, {
          isFullContent: !asideContent,
          isCenter: step === 7,
        })}
      >
        <main>
          <div className="base-layout__header">
            <nav className={titleClass}>
              <AnchorLink to={`/`}>
                <img src={logo} alt="logo" />
              </AnchorLink>
            </nav>
          </div>
          <div className="base-layout__content">{children}</div>

          {hasNavigation && (
            <div className="base-layout__navigation">
              <div className="navigation-actions">
                <button
                  type="button"
                  className="goBack"
                  onClick={handlePreviousStep}
                >
                  <FiChevronLeft />
                  Voltar
                </button>
                {!hasSubmit && (
                  <button
                    type="button"
                    disabled={!isAnswerSelected(step)}
                    onClick={handleNextStep}
                  >
                    Próximo
                  </button>
                )}
                {hasSubmit && (
                  <button
                    type="button"
                    className="submit"
                    disabled={!isAnswerSelected(step)}
                    onClick={handleSubmit}
                  >
                    Calcular Viabilidade
                  </button>
                )}
              </div>
            </div>
          )}

          {footerContent && <footer>{footerContent}</footer>}
        </main>
        {!isMobile && asideContent && (
          <aside className={asideClass}>{asideContent}</aside>
        )}
      </section>
    )
  }
)

export default BaseLayout
