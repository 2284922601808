import { observer } from 'mobx-react-lite'
import React, { useCallback } from 'react'
import stepStore from '../utils/stepStore'

export interface StepNavigationProps {
  step?: number
  handleNextStep?: () => void
  handlePreviousStep?: () => void
}

const withStepNavigation = <P extends object>(
  WrappedComponent: React.ComponentType<P & StepNavigationProps>
) => {
  const WithStepNavigationWrapper: React.FC<P> = observer((props) => {
    const { step } = stepStore

    const handleNextStep = useCallback(() => {
      stepStore.nextStep()
    }, [stepStore])

    const handlePreviousStep = useCallback(() => {
      stepStore.previousStep()
    }, [stepStore])

    return (
      <WrappedComponent
        {...props}
        step={step}
        handleNextStep={handleNextStep}
        handlePreviousStep={handlePreviousStep}
      />
    )
  })

  return WithStepNavigationWrapper
}

export default withStepNavigation
