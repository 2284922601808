import React, { useEffect, useMemo, useState } from 'react'

import { PageProps } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import { SEO } from '../../components/seo'
import { Layout } from '../../components/layout'
import BaseLayout from '../../components/simulator-rpa/base-layout/base-layout'

import './simulador-viabilidade-rpa.styles.scss'
import BusinessSelectorStep from '../../components/simulator-rpa/steps/business'
import withStepNavigation, {
  StepNavigationProps,
} from '../../HOC/withStepNavigation'
import QuestionsStep from '../../components/simulator-rpa/steps/questions'
import { QuestionId } from '../../utils/stepStore'
import {
  questionsOne,
  questionsThree,
  questionsTwo,
} from '../../utils/questionsMap'
import SummaryAside from '../../components/simulator-rpa/summary/summary'
import LoadingStep from '../../components/simulator-rpa/steps/loading/loading'
import ResultStep from '../../components/simulator-rpa/steps/results/results'

const SimulatorRpaPage: React.FC<PageProps & StepNavigationProps> = ({
  step,
  handleNextStep,
  handlePreviousStep,
  location,
}) => {
  const allowNavigation = step ? [3, 4, 5].includes(step) : false
  const allowComplete = step ? [5].includes(step) : false
  const allowTitleIcon = step ? [1, 2].includes(step) : false
  const hasFooter = step !== 7
  const firstStepAsideClass = step === 1 ? 'first-aside' : undefined

  const renderContent = useMemo(() => {
    switch (step) {
      case 1:
      default:
        return (
          <div className="viability-main-content">
            <div className="title">
              <h1>
                Quer avaliar a viabilidade de automação de um processo RPA?
              </h1>
              <p>
                Nosso simulador te ajudará a avaliar a viabilidade técnica e
                econômica para automatizar um processo com RPA.
              </p>
            </div>
            <div className="call-to-action">
              <a
                href="https://api.whatsapp.com/send?phone=5516993982007"
                target="_blank"
              >
                Falar com um consultor
              </a>
              <button type="button" onClick={handleNextStep}>
                Começar agora
              </button>
            </div>
          </div>
        )
      case 2:
        return (
          <BusinessSelectorStep
            handleNextStep={handleNextStep}
            handlePreviousStep={handlePreviousStep}
          />
        )
      case 3:
        return (
          <QuestionsStep
            questionId={QuestionId.SystemsUsed}
            question="Quais são os sistemas utilizados no processo?"
            text="Selecione um ou mais sistemas que alimentam ou recebem informação do processo (ERP, sites, planilhas, aplicativos, etc)."
            answerOptions={questionsOne}
            isMultipleSelection
          />
        )
      case 4:
        return (
          <QuestionsStep
            questionId={QuestionId.TimeSpent}
            question="Quanto tempo é gasto para execução deste processo mensalmente?"
            text="Selecione a quantidade aproximada de horas que são gastas mensalmente para executar este processo."
            answerOptions={questionsTwo}
          />
        )
      case 5:
        return (
          <QuestionsStep
            questionId={QuestionId.StrategicGain}
            question="Certo, e além disso, há algum ganho estratégico esperado com a automação?"
            text="Selecione o ganho estratégico esperado após automação do processo em questão."
            answerOptions={questionsThree}
          />
        )
      case 6:
        return <LoadingStep />
      case 7:
        return <ResultStep />
    }
  }, [step, handleNextStep, handlePreviousStep])

  const renderAsideContent = () => {
    switch (step) {
      case 1:
      default:
        return (
          <div className="main-aside">
            <div className="intro-top">
              <StaticImage
                src="../../images/viability/intro1.png"
                alt="Viabilidade RPA intro"
                title="Viabilidade RPA intro"
                layout="fullWidth"
                placeholder="blurred"
              />
            </div>
            <div className="intro-bottom">
              <StaticImage
                src="../../images/viability/intro2.png"
                alt="Viabilidade RPA"
                title="Viabilidade RPA"
                layout="fullWidth"
                placeholder="blurred"
              />
            </div>
          </div>
        )
      case 2:
        return null
      case 3:
        return <SummaryAside />
      case 4:
        return <SummaryAside />
      case 5:
        return <SummaryAside />
      case 6:
        return null
      case 7:
        return null
    }
  }

  useEffect(() => {
    const bitrixWidget = document.querySelector('[data-b24-crm-button-cont]')

    if (bitrixWidget) {
      bitrixWidget.classList.add('hidden-bitrix')
    }

    document.body.classList.add('simulator-body')
    return () => {
      document.body.classList.remove('simulator-body')
      bitrixWidget?.classList.remove('hidden-bitrix')
    }
  }, [])

  return (
    <Layout location={location} allowBackOnTop={false}>
      <SEO
        title="High Jump - Simulador de viabilidade de automaçāo RPA "
        allowWidgets={false}
      />

      {/* Initial Step */}
      <BaseLayout
        className="viability-main-container"
        footerContent={
          hasFooter ? (
            <div className="copy">
              <span>
                *A simulação é uma estimativa prévia da viabilidade, e portanto,
                não garante os resultados.
              </span>
            </div>
          ) : undefined
        }
        asideContent={renderAsideContent()}
        hasNavigation={allowNavigation}
        hasTitleIcon={allowTitleIcon}
        hasSubmit={allowComplete}
        asideClass={firstStepAsideClass}
      >
        {renderContent}
      </BaseLayout>
    </Layout>
  )
}

export default withStepNavigation(SimulatorRpaPage)
