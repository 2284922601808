import React, { useEffect } from 'react'
import logo from '../../../../images/logo.svg'
import { observer } from 'mobx-react-lite'
import StepStore from '../../../../utils/stepStore'

import './loading.styles.scss'

const LoadingStep: React.FC = observer(() => {
  const { setStep } = StepStore

  useEffect(() => {
    const timer = setTimeout(() => {
      setStep(7)
    }, 2000)

    return () => clearTimeout(timer)
  }, [setStep])

  return (
    <div className="loading-step">
      <div className="content">
        <img src={logo} alt="HighJump" />
      </div>
    </div>
  )
})

export default LoadingStep
