import { makeObservable, observable, action } from 'mobx'

export enum QuestionId {
  SystemsUsed = 'systemsUsed',
  TimeSpent = 'timeSpent',
  StrategicGain = 'strategicGain',
}

export type QuestionAnswers = {
  [key in QuestionId]: string | string[]
}

const StepStore = {
  step: 1,
  selectedCategory: null as number | null,
  formularyCompleted: false,
  questions: {
    [QuestionId.SystemsUsed]: '',
    [QuestionId.TimeSpent]: '',
    [QuestionId.StrategicGain]: '',
  } as QuestionAnswers,
  allowNextStep: false,

  // Método para definir o passo atual
  setStep(newStep: number) {
    StepStore.step = newStep
  },

  // Método para definir a categoria selecionada
  setSelectedCategory(category: number) {
    StepStore.selectedCategory = category
  },

  // Método para salvar a resposta para uma pergunta específica
  saveAnswer(
    questionId: QuestionId,
    answer: string | string[],
    allowMultipleChoice: boolean
  ) {
    if (allowMultipleChoice) {
      const currentAnswers = StepStore.questions[questionId]

      const answersArray = Array.isArray(currentAnswers) ? currentAnswers : []

      const newAnswers = Array.isArray(answer) ? answer : [answer]

      StepStore.questions[questionId] = answersArray.some((ans) =>
        newAnswers.includes(ans)
      )
        ? answersArray.filter((ans) => !newAnswers.includes(ans)) // Remove se já existir
        : [...answersArray, ...newAnswers]
    } else {
      StepStore.questions[questionId] = answer
    }
  },

  isAnswerSelected(step: number): boolean {
    switch (step) {
      case 3:
        return StepStore.questions[QuestionId.SystemsUsed].length > 0
      case 4:
        return StepStore.questions[QuestionId.TimeSpent] !== ''
      case 5:
        return StepStore.questions[QuestionId.StrategicGain] !== ''
      default:
        return true
    }
  },

  // Método para avançar para o próximo passo
  nextStep() {
    if (
      [3, 4, 5].includes(StepStore.step) &&
      !StepStore.isAnswerSelected(StepStore.step)
    ) {
      return false
    }

    if (StepStore.step < 6) {
      StepStore.step += 1 // Incrementa o step
    }
  },

  // Método para voltar ao passo anterior
  previousStep() {
    if (StepStore.step > 1) {
      StepStore.step -= 1 // Decrementa o step
    }
  },

  completeFormulary() {
    StepStore.step = 6
    StepStore.formularyCompleted = true
  },

  resetFormulary() {
    StepStore.step = 1
    StepStore.formularyCompleted = false
    StepStore.questions = {
      [QuestionId.SystemsUsed]: '',
      [QuestionId.TimeSpent]: '',
      [QuestionId.StrategicGain]: '',
    }
    StepStore.selectedCategory = null
  },

  // Métodos getters
  getSelectedCategory() {
    return StepStore.selectedCategory
  },

  getQuestions() {
    const groupedQuestions = Object.entries(StepStore.questions).map(
      ([questionId, answers]) => ({
        questionId,
        answers: Array.isArray(answers) ? answers : [answers],
      })
    )

    return groupedQuestions
  },

  getAnswer(questionId: QuestionId): string[] {
    const answer = StepStore.questions[questionId]

    if (typeof answer === 'string') {
      return answer ? [answer] : []
    }

    return answer
  },
}

makeObservable(StepStore, {
  step: observable,
  selectedCategory: observable,
  questions: observable,
  formularyCompleted: observable,
  allowNextStep: observable,
  setStep: action,
  setSelectedCategory: action,
  saveAnswer: action,
  nextStep: action,
  previousStep: action,
  completeFormulary: action,
  resetFormulary: action,
  isAnswerSelected: observable,
})

// Inicializando o StepStore
StepStore.constructor()

export default StepStore
