type AutomationResult = {
  viability: string
  payback: string
  roi: string
}

export type UserSelections = {
  systems: string[] // valores selecionados de questionsOne
  timeSpent: string // valor selecionado de questionsTwo
  strategicGain: string // valor selecionado de questionsThree
}

export const determineAutomationViability = (
  selections: UserSelections
): AutomationResult => {
  const { systems, timeSpent, strategicGain } = selections

  // Verificar se algum sistema inviabiliza a automação
  const isSystemInviable =
    systems.includes('internet-banking') || systems.includes('manuscrito')

  // Verificar se o tempo inviabiliza a automação
  const isTimeInviable = timeSpent === '32'

  // Verificar se o ganho estratégico inviabiliza a automação
  const isGainInviable = strategicGain === 'N/I'

  // Determinar se a automação é viável
  const isAutomationViable =
    !isSystemInviable && !isTimeInviable && !isGainInviable

  if (!isAutomationViable) {
    return {
      viability: 'Não, aparentemente a automação não é viável.',
      payback: 'Não aplicável.',
      roi: 'Não aplicável.',
    }
  }

  // Se a automação for viável, determinar payback e ROI com base no tempo selecionado
  let payback: string
  let roi: string

  switch (timeSpent) {
    case '96':
      payback = 'A automação deste processo se pagará em 5 meses.'
      roi =
        'Após 5 meses, a automação deste processo trará um ROI estimado de 6% mensais.'
      break
    case '160':
      payback = 'A automação deste processo se pagará em 4 meses.'
      roi =
        'Após 4 meses, a automação deste processo trará um ROI estimado de 45% mensais.'
      break
    case '161':
      payback = 'A automação deste processo se pagará em 3 meses.'
      roi =
        'Após 3 meses, a automação deste processo trará um ROI estimado de 50% mensais.'
      break
    default:
      payback = 'Não aplicável.'
      roi = 'Não aplicável.'
  }

  return {
    viability: 'Sim, aparentemente a automação é viável e trará resultados.',
    payback,
    roi,
  }
}
