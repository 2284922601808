import React from 'react'
import { Checkbox, Radio } from 'antd'

import TypingTextComponent from '../../typing-text/typing-text'
import { StepNavigationProps } from '../../../../HOC/withStepNavigation'

import './questions.styles.scss'
import { observer } from 'mobx-react-lite'
import StepStore, { QuestionId } from '../../../../utils/stepStore'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'

interface QuestionsStepProps {
  question: string
  text: string
  questionId: string
  answerOptions: {
    value: string
    label: string
  }[]
  isMultipleSelection?: boolean
}

const QuestionsStep: React.FC<QuestionsStepProps & StepNavigationProps> =
  observer(
    ({ questionId, question, text, answerOptions, isMultipleSelection }) => {
      const { saveAnswer, getAnswer } = StepStore

      const isOptionSelected = (value: string) => {
        const currentAnswers = getAnswer(questionId as QuestionId)
        if (Array.isArray(currentAnswers)) {
          return currentAnswers.includes(value)
        }
        return currentAnswers === value
      }

      const onChange = (e: CheckboxChangeEvent, item: any) => {
        if (isMultipleSelection) {
          saveAnswer(questionId as QuestionId, item.value, true)
        } else {
          saveAnswer(questionId as QuestionId, item.value, false)
        }
      }

      return (
        <div className="question-step viability-default-content">
          <div className="question-text">
            <TypingTextComponent text={question} />
            <span>{text}</span>

            {isMultipleSelection && (
              <div className="questions">
                {answerOptions.map((item, key) => (
                  <Checkbox
                    key={key}
                    value={item.value}
                    onChange={(e) => {
                      onChange(e, item)
                    }}
                    checked={isOptionSelected(item.value)}
                  >
                    {item.label}
                  </Checkbox>
                ))}
              </div>
            )}

            {!isMultipleSelection && (
              <div className="questions">
                {answerOptions.map((item, key) => (
                  <Radio
                    key={key}
                    value={item.value}
                    name={`question-radio`}
                    onChange={(e) => {
                      onChange(e, item)
                    }}
                    checked={isOptionSelected(item.value)}
                  >
                    {item.label}
                  </Radio>
                ))}
              </div>
            )}
          </div>
        </div>
      )
    }
  )

export default QuestionsStep
